import React from "react";
import "./Header.css";
const Header = () => {
	return (
		<>
			<a name="home"></a>
			<div className="header">
				<a name="about"></a>
				<div className="container">
					<div className="header-part" data-aos="fade-right">
						<div className="header-text mb-3">
							The World's Most Advanced Proxy Network.
						</div>
						<div className="header-deatils mb-3">
							Offering residential, DC, ISP & even Mobile ISPs for all your
							needs!
						</div>
						<div className="header-btn">
							<button type="button" className="btn-getstarted">
								Get Started
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="container mb-5">
				<div className="col-12 mx-auto">
					<div
						className="box-part d-flex flex-column flex-lg-row align-items-start  align-items-lg-center  mb-5 justify-content-between p-4"
						data-aos="fade-left"
					>
						<div className="flex-item my-3">
							<div className="inner-flex d-flex align-items-center">
								<div className="icons">
									<i class="fas fa-user"></i>
								</div>
								<div className="flex-item-text mx-3">
									<div className="top">5 Billion+</div>
									<div className="bottom-text">Monthly Requests</div>
								</div>
							</div>
						</div>
						<div className="flex-item my-3">
							<div className="inner-flex d-flex align-items-center">
								<div className="icons">
									<i class="fas fa-map-marker-alt"></i>
								</div>
								<div className="flex-item-text mx-3">
									<div className="top">100 +</div>
									<div className="bottom-text">Countries </div>
								</div>
							</div>
						</div>
						<div className="flex-item my-3">
							<div className="inner-flex d-flex align-items-center">
								<div className="icons">
									<i class="fas fa-server"></i>
								</div>
								<div className="flex-item-text mx-3">
									<div className="top">120 Million +</div>
									<div className="bottom-text">IP in our pool</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
