import React from "react";
import "./Footer.css";
import logo from "../Images/atlas-proxies_3 1.png";
import facebook from "../Images/Facebook.png";
import twitter from "../Images/Twitter.png";
import instragram from "../Images/Instagram.png";
const Footer = () => {
	return (
		<div className="bootom-part">
			<div className="container-fluid bottom">
				<div className="row position">
					<div className="col-10 mx-auto">
						<div className="subscribe p-4">
							<div className="subscribe-text mb-3">Join Waitlist Now!</div>
							<div className="input  d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center ">
								<div className="right-part mb-3">
									<a
										href="https://whop.com/products/atlasproxies/4853"
										target="_blank"
										rel="noopener noreferrer"
									>
										<button type="button" class="btn btn-primary btn-subscribe">
											Join waitlist
										</button>
									</a>
								</div>
							</div>
							<div className="subscribe-deatils">
								Join waitlist for invite opportunities
							</div>
						</div>
					</div>
				</div>
			</div>
			<footer className="footer mt-5 p-5">
				<div className="container footer-width">
					<div class=" row footer-grid">
						<div class="col-10 mx-auto text-lg-start col-lg-7">
							<div className="d-flex align-items-end mb-3">
								<div className="logo-footer">
									<img src={logo} alt="" />
								</div>
								<div className="logo-title">Atlas Proxies</div>
							</div>
							<div className="deatils-part-footer mb-4">
								Atlas Proxies is a private proxies network
							</div>
							<div className="icon mb-4 d-flex">
								<a
									href="https://www.facebook.com/AtlasProxies0001"
									target="_blank"
								>
									<div className="social-icon">
										<i class="fab fa-facebook-f"></i>
									</div>
								</a>
								<a href="https://twitter.com/atlas_proxies" target="_blank">
									<div className="social-icon mx-3">
										<i class="fab fa-twitter"></i>
									</div>
								</a>
								<a
									href="https://www.instagram.com/atlasproxies/"
									target="_blank"
								>
									<div className="social-icon">
										<i class="fab fa-instagram"></i>
									</div>
								</a>
							</div>
							<div className="copyright">©AtlasProxies Inc.</div>
						</div>

						<div className="col-10 mx-auto col-lg-2">
							<h5 className="engage">Engage</h5>
							<li>
								<a
									href=" https://truth-cupcake-405.notion.site/FAQ-eeca0a1b538d4efabd908479edc53710"
									target="_blank"
								>
									FAQ
								</a>
							</li>
							<li>
								<a
									href=" https://truth-cupcake-405.notion.site/Refund-Policy-c7e93f57610146bd8eb5250fb8dd1c08"
									target="_blank"
								>
									Refund Policy
								</a>
							</li>
							<li>
								<a
									href="https://truth-cupcake-405.notion.site/Privacy-Statement-e563a5aed4ae4c41982290b1dc1d34af"
									target="_blank"
								>
									Privacy Policy
								</a>
							</li>
							<li>
								<a
									href=" https://truth-cupcake-405.notion.site/Terms-Of-Service-c11c0e879ba44a31acd300c931edd751"
									target="_blank"
								>
									Terms of Service
								</a>
							</li>
						</div>

						<div className="col-10 mx-auto col-lg-3">
							<h5 className="engage">Earn Free Data</h5>
							<li>
								<a href="#">Rewards Program In Discord</a>
							</li>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
