import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Header from "./Components/Header/Header";
import Feature from "./Components/Feature/Feature";
import Pricing from "./Components/Pricing/Pricing";
import Footer from "./Components/Footer/Footer";

function App() {
	return (
		<div className="App">
			<Navbar />
			<Header />
			<Feature />
			<Pricing />
			<Footer />
		</div>
	);
}

export default App;
