import React from "react";
import "./Pricing.css";
import map from "../Images/Vector (4).png";
import logo from "../Images/Group 1307.png";
import logo2 from "../Images/Group_24 1.png";
import logo3 from "../Images/Vector.png";
import logo4 from "../Images/facebook (3).png";
import free from "../Images/Free.png";
import standard from "../Images/Standard.png";
import premium from "../Images/Premium.png";
import right from "../Images/Vector (3).png";

import Carosoul from "../Carosoul/Carosoul";
const Pricing = () => {
	return (
		<div className="pricing">
			<div className="container p-3">
				<a name="pricing"></a>
				<div className="choose-path mt-5 mb-2" data-aos="fade-up">
					Choose Your Class
				</div>
				<div className="pricing-deatils mb-5 text-center" data-aos="fade-up">
					Choose Platinum Class For Faster Checkouts On Elite Drops <br />{" "}
					Choose Gold Class For Fast Restock Checkouts
					<br />
					Choose Silver Class For Farming And No Antibot Sites
				</div>
				{/* card part  */}
				<div className="grid-card">
					{/* card box 1 */}
					<div className="card-box mx-auto p-4" data-aos="zoom-in-up">
						<div className="card-box-image text-center mb-3">
							<img src={standard} alt="" className="img-fluid " />
						</div>
						<div className="card-img-title text-center">Silver</div>
						{/* bottom feature */}
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">No Expiration</div>
						</div>
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">
								Pools with 7 Million residential IPs
							</div>
						</div>
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">No Traffic Logs</div>
						</div>
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">
								Rotating /Sticky options support
							</div>
						</div>
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">All Regions/Countries</div>
						</div>
						<div className="">
							<div className="card-img-title text-center mt-2 mb-3">
								Invite Only
							</div>
						</div>
						<div className="text-center">
							<a
								href="https://dashboard.atlasproxies.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<button type="button" className="select">
									Select
								</button>
							</a>
						</div>
					</div>
					{/* card box 2 */}
					<div className="card-box mx-auto p-4" data-aos="zoom-in-up">
						<div className="card-box-image text-center mb-3">
							<img src={premium} alt="" className="img-fluid " />
						</div>
						<div className="card-img-title text-center">Gold</div>
						{/* bottom feature */}
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">No Expiration</div>
						</div>
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">
								Pools with 70 Million residential IPs
							</div>
						</div>
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">Private network</div>
						</div>
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">Never Throttled</div>
						</div>
						<div className="d-flex mt-3 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">Connect Anyware</div>
						</div>
						<div className="">
							<div className="card-img-title text-center mt-2 mb-3">
								Invite Only
							</div>
						</div>
						<div className="text-center">
							<a
								href="https://dashboard.atlasproxies.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<button type="button" className="select">
									Select
								</button>
							</a>
						</div>
					</div>
					{/* card-box3  */}
					<div className="card-box mx-auto p-4" data-aos="zoom-in-up">
						<div className="card-box-image text-center mb-3">
							<img src={free} alt="" className="img-fluid " />
						</div>
						<div className="card-img-title text-center">Platinum</div>
						{/* bottom feature */}
						<div className="d-flex mt-2 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">No Expiration</div>
						</div>
						<div className="d-flex mt-2 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">
								Access to 100 Million residential IPs
							</div>
						</div>
						<div className="d-flex mt-2 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">Instant delivery</div>
						</div>
						<div className="d-flex mt-2 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">Unbanned on all sites</div>
						</div>
						<div className="d-flex mt-2 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">Connect Anyware</div>
						</div>
						<div className="d-flex mt-2 align-items-center">
							<div className="right">
								<img src={right} alt="" />
							</div>
							<div className="right-text mx-3">Extremely fast on Footsites</div>
						</div>
						<div className="">
							<div className="card-img-title text-center mt-2 mb-3">
								Invite Only
							</div>
						</div>
						<div className="mx-auto text-center">
							<a
								href="https://dashboard.atlasproxies.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<button type="button" className="select">
									Select
								</button>
							</a>
						</div>
					</div>
				</div>
				{/* map part  */}
				<div className="choose-path mt-5 mb-3 text-center" data-aos="fade-up">
					Huge Global Network of <br /> Fast Proxies
				</div>
				<div className="pricing-deatils mb-5 text-center" data-aos="fade-up">
					Fast pings for faster checkouts
				</div>
				{/* map logo  */}
				<div className="img w-100 mt-5 mb-5 text-center" data-aos="zoom-in">
					<img src={map} className="img-fluid text-center mt-5 mb-5" alt="" />
				</div>
				{/* other logo  */}
				<div className="choose-path mt-5 mb-5 text-center" data-aos="fade-up">
					SUPPORTING A WIDE VARIETY OF SITES
				</div>
				<div className="mx-auto">
					<div className="img mt-5 mb-5" data-aos="zoom-in-up">
						<img src={logo} className="img-fluid" alt="" />
					</div>
				</div>
				<div className="d-flex mb-5 justify-content-center align-items-center">
					<div className="img mx-3" data-aos="zoom-in-up">
						<img src={logo3} className="img-fluid" alt="" />
					</div>
					<div className="img mx-3" data-aos="zoom-in-up">
						<img src={logo4} className="img-fluid" alt="" />
					</div>
				</div>
				<a name="Testimonials"></a>
				<Carosoul />
			</div>
		</div>
	);
};

export default Pricing;
