import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import user from "../Images/Ellipse 175.png";
import user1 from "../Images/Ellipse 176.png";
import user2 from "../Images/Ellipse 175 (1).png";
import user3 from "../Images/BcINeMVCIAABeWd.jpeg";
import user4 from "../Images/photo-1500648767791-00dcc994a43e.jpeg";
import user5 from "../Images/photo-1438761681033-6461ffad8d80.jpeg";
import left from "../Images/left.png";
import right from "../Images/right.svg";
import "./carosoul.css";

export default class Carosoul extends Component {
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render() {
		var settings = {
			dots: true,
			arrows: false,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,

			responsive: [
				{
					breakpoint: 1254,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true,
					},
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};
		return (
			<div className="carosoul">
				<div className="right-title text-center mt-5 mb-5">Gallery</div>
				<div className="col-12 mx-auto">
					<div>
						<div className=" carousel-inner mb-5">
							<Slider
								ref={(c) => (this.slider = c)}
								{...settings}
								className="mb-5"
								data-aos="fade-up"
							>
								{/* testimonial 1 */}
								<div
									className="grid-box-slider carousel-item active p-4"
									data-aos="zoom-in-up"
								>
									<div className="top-part mb-4 d-flex justify-content-between  align-items-center">
										<div className="d-flex align-items-center">
											<div className="user-img">
												<img src={user} alt="" />
											</div>
											<div className="name mx-3">
												<div className="user-name">Eliseo P.</div>
												<div className="user-profession">Queens, NY</div>
											</div>
										</div>
										<div className="rating">
											5{" "}
											<span className="ms-3">
												<i class="fas fa-star"></i>
											</span>
										</div>
									</div>
									<div className="bottom-deatails mb-3">
										I cant say enough how grateful I am for the quality of
										service here. I was able to score a pair of Yeezy!
									</div>
								</div>
								{/* testimonial 2 */}

								<div
									className="grid-box-slider carousel-item active p-4"
									data-aos="zoom-in-up"
								>
									<div className="top-part mb-4 d-flex justify-content-between  align-items-center">
										<div className="d-flex align-items-center">
											<div className="user-img">
												<img src={user1} alt="" />
											</div>
											<div className="name mx-3">
												<div className="user-name">Jasmin B.</div>
												<div className="user-profession">Sacremento, CA</div>
											</div>
										</div>
										<div className="rating">
											5{" "}
											<span className="ms-3">
												<i class="fas fa-star"></i>
											</span>
										</div>
									</div>
									<div className="bottom-deatails mb-3">
										Very happy I was able to purchase a pair of Jordans for my
										boyfriend! Thanks Atlas!
									</div>
								</div>
								{/* testimonial 3 */}
								<div
									className="grid-box-slider carousel-item active p-4"
									data-aos="zoom-in-up"
								>
									<div className="top-part mb-4 d-flex justify-content-between  align-items-center">
										<div className="d-flex align-items-center">
											<div className="user-img">
												<img src={user2} alt="" />
											</div>
											<div className="name mx-3">
												<div className="user-name">Jason H.</div>
												<div className="user-profession">
													Goldenrod, Florida
												</div>
											</div>
										</div>
										<div className="rating">
											4.9{" "}
											<span className="ms-3">
												<i class="fas fa-star"></i>
											</span>
										</div>
									</div>
									<div className="bottom-deatails mb-3">
										Got my son's birthday present with these proxies! He thanks
										your for the Playstation 5!
									</div>
								</div>
								{/* testimonial 4 */}
								<div
									className="grid-box-slider carousel-item active p-4"
									data-aos="zoom-in-up"
								>
									<div className="top-part mb-4 d-flex justify-content-between  align-items-center">
										<div className="d-flex align-items-center">
											<div className="user-img">
												<img src={user3} className="img-slide" alt="" />
											</div>
											<div className="name mx-3">
												<div className="user-name">Justin R.</div>
												<div className="user-profession">Tampa, Fl</div>
											</div>
										</div>
										<div className="rating">
											5{" "}
											<span className="ms-3">
												<i class="fas fa-star"></i>
											</span>
										</div>
									</div>
									<div className="bottom-deatails mb-3">
										After using Atlas for a few weeks, I can confidently say
										they are hands down one of the best providers. If I ever run
										into issues the customer service is outstanding! Pro Tip Use
										Platinum Class!
									</div>
								</div>
								{/* testimonial 5 */}
								<div
									className="grid-box-slider carousel-item active p-4"
									data-aos="zoom-in-up"
								>
									<div className="top-part mb-4 d-flex justify-content-between  align-items-center">
										<div className="d-flex align-items-center">
											<div className="user-img">
												<img src={user4} className="img-slide" alt="" />
											</div>
											<div className="name mx-3">
												<div className="user-name">Ralph C.</div>
												<div className="user-profession">Charlotte, NC</div>
											</div>
										</div>
										<div className="rating">
											5{" "}
											<span className="ms-3">
												<i class="fas fa-star"></i>
											</span>
										</div>
									</div>
									<div className="bottom-deatails mb-3">
										I run drops daily and constantly run into throttling but not
										here. Atlas Proxies is truly a gem. Get invited if you can!
									</div>
								</div>
								{/* testimonial 6 */}
								<div
									className="grid-box-slider carousel-item active p-4"
									data-aos="zoom-in-up"
								>
									<div className="top-part mb-4 d-flex justify-content-between  align-items-center">
										<div className="d-flex align-items-center">
											<div className="user-img">
												<img src={user5} className="img-slide" alt="" />
											</div>
											<div className="name mx-3">
												<div className="user-name">Ashley C.</div>
												<div className="user-profession">Norfolk, VA </div>
											</div>
										</div>
										<div className="rating">
											5{" "}
											<span className="ms-3">
												<i class="fas fa-star"></i>
											</span>
										</div>
									</div>
									<div className="bottom-deatails mb-3">
										Incredible Customer Service! There rewards program basically
										gives me free data as long as I tweet success. My go to
										provider every time!
									</div>
								</div>
							</Slider>
							<div
								className="icon-flex mb-5
			 d-flex justify-content-between  align-items-center"
							>
								{/* <div className="left-part d-md-flex d-none" data-aos="zoom-in">
					<div className="box"></div>
					<div className="round ms-2"></div>
					<div className="round ms-2"></div>
					<div className="round ms-2"></div>
				</div> */}
								<div className="left-part mx-auto d-flex " data-aos="zoom-in">
									<div className="left-img mx-3" onClick={this.previous}>
										<img src={left} alt="" />
									</div>
									<div className="right-img mx-3" onClick={this.next}>
										<i class="fas fa-arrow-right"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
