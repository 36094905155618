import React from "react";
import "./Feature.css";
import imgae from "../Images/Group 1304 (1).png";
import tick from "../Images/Vector (2).png";
const Feature = () => {
	return (
		<div className="container feature-part">
			<a name="features"></a>
			<div className="row mt-5 mb-5 align-items-center">
				<div className="col-10 col-lg-6 mb-lg-0 mx-auto" data-aos="fade-up">
					<img src={imgae} className="img-fluid mb-5 mb-lg-0" alt="" />
				</div>
				<div
					className="col-10 col-lg-6 mt-lg-5 mx-auto align-items-center"
					data-aos="fade-down"
				>
					<div className="title-feature text-center text-lg-start mb-3 mt-5 mt-lg-0">
						Fast and Reliable
					</div>
					<div className="deatails-feature text-center text-lg-start mb-5">
						Easy checkouts on some of the hardest sites.
					</div>
					<div className="feature d-flex mb-2  align-items-center">
						<div className="img-tick">
							<img src={tick} alt="" />
						</div>
						<div className="feature-name ms-4">
							Private pools with 70 Million residential IPs
						</div>
					</div>
					<div className="feature d-flex mb-2  align-items-center">
						<div className="img-tick">
							<img src={tick} alt="" />
						</div>
						<div className="feature-name ms-4">Instant delivery</div>
					</div>
					<div className="feature d-flex mb-2  align-items-center">
						<div className="img-tick">
							<img src={tick} alt="" />
						</div>
						<div className="feature-name ms-4">All plans never expire</div>
					</div>
					<div className="feature d-flex mb-2  align-items-center">
						<div className="img-tick">
							<img src={tick} alt="" />
						</div>
						<div className="feature-name ms-4">
							Private network and never throttled EVER
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feature;
