import React, { useState } from "react";

import "./Navbar.css";
import logo from "../Images/Frame (1).png";
const Navbar = () => {
	const [color, setColor] = useState(false);
	const changeColor = () => {
		if (window.scrollY >= 110) {
			setColor(true);
		} else {
			setColor(false);
		}
	};
	window.addEventListener("scroll", changeColor);

	return (
		<nav
			className={
				color
					? "navbar navbar-expand-lg sticky-top scroll box-shadow p-3 "
					: "navbar navbar-expand-lg sticky-top withoutScroll p-3"
			}
		>
			<div className="container-fluid">
				<a
					className={color ? "navbar-brand d-none" : "navbar-brand"}
					href="#home"
				>
					<img src={logo} alt="" className="logo img-fluid" />
				</a>
				<button
					class="navbar-toggler collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="toggler-icon top-bar"></span>
					<span className="toggler-icon middle-bar"></span>
					<span className="toggler-icon bottom-bar"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav ms-auto">
						<li class="nav-item mx-3 mb-lg-0 mb-2">
							<a class="nav-link" href="#about">
								About
							</a>
						</li>
						<li class="nav-item mx-3 mb-lg-0 mb-2">
							<a class="nav-link" href="#features">
								Features
							</a>
						</li>
						<li class="nav-item mx-3 mb-lg-0 mb-2">
							<a class="nav-link" href="#pricing">
								Pricing
							</a>
						</li>
						<li class="nav-item mx-3 mb-lg-0 mb-2">
							<a class="nav-link" href="#Testimonials">
								Testimonials
							</a>
						</li>
						<a
							href="https://dashboard.atlasproxies.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button type="button" className="button ms-lg-5">
								Dashboard
							</button>
						</a>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
